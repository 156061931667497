/* stylelint-disable */
.svg-404-dims {
  width: 885px;
  height: 629px;
}

.svg-account-dims {
  width: 24px;
  height: 24px;
}

.svg-addtocart-dims {
  width: 23.97px;
  height: 20.25px;
}

.svg-affiliate-home-dims {
  width: 25px;
  height: 25px;
}

.svg-affiliate-logout-dims {
  width: 26px;
  height: 26px;
}

.svg-affiliate-profil-dims {
  width: 21px;
  height: 25px;
}

.svg-app-store-dims {
  width: 151px;
  height: 48px;
}

.svg-arrow-back-dims {
  width: 28px;
  height: 24px;
}

.svg-arrow-enter-dims {
  width: 21px;
  height: 22px;
}

.svg-arrow-left-dims {
  width: 58px;
  height: 58px;
}

.svg-arrow-right-dims {
  width: 58px;
  height: 58px;
}

.svg-back-dims {
  width: 19px;
  height: 12px;
}

.svg-back-small-dims {
  width: 15px;
  height: 12px;
}

.svg-basket-dims {
  width: 40px;
  height: 40px;
}

.svg-brand-dims {
  width: 21px;
  height: 26px;
}

.svg-bulle-dims {
  width: 25px;
  height: 24px;
}

.svg-burger-dims {
  width: 16px;
  height: 16px;
}

.svg-burger-white-dims {
  width: 25px;
  height: 19px;
}

.svg-caces-dims {
  width: 29px;
  height: 24px;
}

.svg-call-dims {
  width: 17px;
  height: 18px;
}

.svg-card-dims {
  width: 30px;
  height: 30px;
}

.svg-caret-dims {
  width: 22px;
  height: 22px;
}

.svg-cart-dims {
  width: 16px;
  height: 16px;
}

.svg-check-dims {
  width: 16px;
  height: 13px;
}

.svg-check-blue-dims {
  width: 16px;
  height: 13px;
}

.svg-check-white-dims {
  width: 16px;
  height: 13px;
}

.svg-clock-dims {
  width: 30px;
  height: 30px;
}

.svg-close-dims {
  width: 22px;
  height: 22px;
}

.svg-contract-dims {
  width: 44px;
  height: 45px;
}

.svg-copy-dims {
  width: 13px;
  height: 15px;
}

.svg-cv-dims {
  width: 26px;
  height: 24px;
}

.svg-dashboard-dims {
  width: 26px;
  height: 20px;
}

.svg-decide.work-dims {
  width: 300px;
  height: 43px;
}

.svg-delete-dims {
  width: 20px;
  height: 20px;
}

.svg-dots-dims {
  width: 4px;
  height: 18px;
}

.svg-download-dims {
  width: 19px;
  height: 17px;
}

.svg-download-2-dims {
  width: 22px;
  height: 24px;
}

.svg-dropdown_arrow-dims {
  width: 16px;
  height: 11px;
}

.svg-dw-dims {
  width: 181px;
  height: 113px;
}

.svg-dw-logo-pink-dims {
  width: 180px;
  height: 113px;
}

.svg-edit-dims {
  width: 20px;
  height: 20px;
}

.svg-efficient-dims {
  width: 58px;
  height: 49px;
}

.svg-empty-resume-dims {
  width: 238px;
  height: 220px;
}

.svg-enter-dims {
  width: 22px;
  height: 15px;
}

.svg-eye-dims {
  width: 24px;
  height: 24px;
}

.svg-eye-hide-dims {
  width: 24px;
  height: 24px;
}

.svg-facebook-dims {
  width: 16px;
  height: 16px;
}

.svg-full-basket-dims {
  width: 40px;
  height: 40px;
}

.svg-gear-dims {
  width: 19px;
  height: 20px;
}

.svg-google-play-dims {
  width: 161px;
  height: 48px;
}

.svg-goto-dims {
  width: 16px;
  height: 17px;
}

.svg-heart-dims {
  width: 24px;
  height: 24px;
}

.svg-icon_eye-dims {
  width: 24px;
  height: 24px;
}

.svg-info-dims {
  width: 20px;
  height: 20px;
}

.svg-info-round-dims {
  width: 24px;
  height: 24px;
}

.svg-innove-01-dims {
  width: 155px;
  height: 146px;
}

.svg-innove-02-dims {
  width: 148px;
  height: 140px;
}

.svg-innove-03-dims {
  width: 150px;
  height: 137px;
}

.svg-instagram-dims {
  width: 22px;
  height: 22px;
}

.svg-invoice-pdf-dims {
  width: 44px;
  height: 45px;
}

.svg-job-dims {
  width: 23px;
  height: 21px;
}

.svg-level-dims {
  width: 30px;
  height: 26px;
}

.svg-linkedin-dims {
  width: 16px;
  height: 16px;
}

.svg-lock-dims {
  width: 20px;
  height: 29px;
}

.svg-logout-dims {
  width: 22px;
  height: 19px;
}

.svg-long-arrow-down-dims {
  width: 24px;
  height: 42px;
}

.svg-long-arrow-right-dims {
  width: 82px;
  height: 24px;
}

.svg-magnifier-dims {
  width: 22px;
  height: 22px;
}

.svg-marker-dims {
  width: 28px;
  height: 22px;
}

.svg-missing-cart-dims {
  width: 254px;
  height: 250px;
}

.svg-missing-favorites-dims {
  width: 234px;
  height: 250px;
}

.svg-missing-purchases-dims {
  width: 288px;
  height: 250px;
}

.svg-my-qr-code-dims {
  width: 21px;
  height: 21px;
}

.svg-pink-trash-dims {
  width: 30px;
  height: 30px;
}

.svg-plus-dims {
  width: 16px;
  height: 16px;
}

.svg-plus-no-border-dark-dims {
  width: 15px;
  height: 14px;
}

.svg-plus_dark-dims {
  width: 16px;
  height: 16px;
}

.svg-print-dims {
  width: 18px;
  height: 17px;
}

.svg-profile-dims {
  width: 21px;
  height: 25px;
}

.svg-qr-code-dims {
  width: 58px;
  height: 54px;
}

.svg-qrcode-small-dims {
  width: 21px;
  height: 21px;
}

.svg-qrcode_download-dims {
  width: 28px;
  height: 28px;
}

.svg-resume-dims {
  width: 24px;
  height: 29px;
}

.svg-right-dims {
  width: 29px;
  height: 26px;
}

.svg-right-1-dims {
  width: 29px;
  height: 26px;
}

.svg-right-2-dims {
  width: 29px;
  height: 26px;
}

.svg-right-3-dims {
  width: 29px;
  height: 26px;
}

.svg-right-arrow-dims {
  width: 9px;
  height: 16px;
}

.svg-right-simple-arrow-dims {
  width: 15px;
  height: 16px;
}

.svg-right-white-dims {
  width: 14px;
  height: 11px;
}

.svg-shop-dims {
  width: 18px;
  height: 22px;
}

.svg-shop-car-dims {
  width: 131px;
  height: 79px;
}

.svg-slider-arrow-left-dims {
  width: 24px;
  height: 24px;
}

.svg-slider-arrow-left-darkBlueGrey-dims {
  width: 7px;
  height: 13px;
}

.svg-slider-arrow-right-dims {
  width: 24px;
  height: 24px;
}

.svg-slider-arrow-right-darkBlueGrey-dims {
  width: 6px;
  height: 12px;
}

.svg-small-close-dims {
  width: 15px;
  height: 16px;
}

.svg-speed-dims {
  width: 60px;
  height: 51px;
}

.svg-sticker-dims {
  width: 30px;
  height: 30px;
}

.svg-trash-dims {
  width: 20px;
  height: 20px;
}

.svg-trash-alt-dims {
  width: 30px;
  height: 30px;
}

.svg-twitter-dims {
  width: 64px;
  height: 64px;
}

.svg-upload-dims {
  width: 24px;
  height: 24px;
}

.svg-validate-dims {
  width: 24px;
  height: 24px;
}

/* stylelint-enable */
