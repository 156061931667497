.form-group {
  color: $steel;
  margin: 0 0 $spacer;
}

.form-group-required {
  label::after {
    content: ' *';
  }
}

.required-fields {
  color: $gray-900;
}

.input-group {
  > .form-control:not([role='searchbox']) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.form-control {
  border-radius: 16px;
  line-height: 1;
  padding: 13px 20px;
  // Prevent zooming on iOS
  @supports (-webkit-overflow-scrolling: touch) {
    font-size: 16px;
  }

  .file-upload & {
    color: $steel;
    padding-inline: 15px;

    @include mq($from: mobile) {
      padding-inline: 20px;
    }
  }
}

.form-control[type=file] {
  padding: 10px 12px;
}

.custom-switch {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: $spacer;

  > * {
    cursor: pointer;
  }

  .form-check-label {
    margin-top: 7px;
  }
}

.form-check-custom-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .form-check {
    margin: 0;
    padding: 0;
  }
}

.custom-check {
  padding: 0;

  .form-check-label {
    align-items: center;
    border: 1px solid $steel;
    border-radius: 16px;
    color: $steel;
    cursor: pointer;
    display: inline-flex;
    height: 54px;
    line-height: 1;
    padding-inline: 20px;
  }

  input {
    display: none;

    &:checked + .form-check-label {
      background-color: $purple-200;
      border-color: $primary;
      color: $primary;
    }
  }
}

.form-check-input {
  height: 22px;
  min-width: 22px;
  width: 22px;

  &:checked {
    background-color: $white;
    background-size: 13px;
    border-color: rgba(0, 0, 0, .25);
  }
}
