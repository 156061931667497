
@import '../../scss/config/config';

.svg-magnifier {
  bottom: 0;
  margin: auto 0;
  position: absolute;
  right: 30px;
  top: 0;
}

.searchbar {
  background-color: #574cda;
  border: 0;
  border-radius: 30px;
  box-sizing: border-box;
  color: $white;
  height: 50px;
  outline: $white;
  padding: 13px 57px 13px 20px;
  width: 100%;

  &::placeholder {
    color: $white;
  }

  .store-switch-list & {
    background-color: $white;
    box-shadow: 0 0 15px rgba(0, 0, 0, .15);
    color: $body-color;
    font-size: 14px;
    height: 42px;
    padding-bottom: 13px;
    padding-top: 13px;

    &::placeholder {
      color: $body-color;
      opacity: 1;
    }

    + .svg-magnifier {
      right: 15px;
    }
  }
}
