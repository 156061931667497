.landing-affiliation {
  --body-font-family: Roboto, #{$font-family-sans-serif};
  --yellow-fluo: #dcff67;
  --header-height: 100px;

  font-family: var(--body-font-family);

  @include mq($from: tablet) {
    --header-height: 160px;
  }

  > .content {
    background-color: #f8f7ec;
    margin-top: var(--header-height);
    overflow: clip;

    @include mq($from: tablet) {
      padding-inline: 40px;
    }

    @include mq($from: wide) {
      padding-inline: 90px;
    }
  }

  .h1,
  .h2 {
    font-family: $special-font, $font-family-base;
  }

  .anchor-nav {
    display: none;

    @include mq($from: wide) {
      display: flex;
      right: 60px;
    }
  }

  .anchor-nav-link {
    border: 1px solid $black;

    &.active {
      background-color: $black;
    }
  }
}

.affiliation-section {
  --radius: 20px;

  background-color: $gray-900;
  color: $body-color;
  min-height: 60vh;
  padding-block: 60px calc(60px + var(--radius));
  padding-inline: 20px;
  place-content: center;
  position: relative;

  @include mq($from: tablet) {
    border-radius: var(--radius) var(--radius) 0 0;
    padding-inline: 40px;
  }

  @include mq($from: desktop) {
    --radius: 40px;

    padding-block: 100px calc(100px + var(--radius));
  }

  + .affiliation-section {
    margin-top: calc(-1 * var(--radius));
  }

  &.light {
    background-color: var(--yellow-fluo);
  }

  &.purple {
    background-color: #ad94f6;
  }
}

.affiliation-container {
  margin-inline: auto;
  max-width: 100%;
  width: 1090px;
}

.affiliation-container-xl {
  margin-inline: auto;
  max-width: 100%;
  width: 1460px;
}

:where(.affiliation-container, .affiliation-container-xl) {
  .content {
    background-color: #f8f7ec;
    border-radius: 20px;
    box-shadow: 0 0 5px 0 #00000029;
    padding: 30px 15px 20px;
    text-wrap: pretty;

    @include mq($from: tablet) {
      text-align: center;
    }

    @include mq($from: desktop) {
      border-radius: 50px;
      font-size: 20px;
      padding: 80px 40px 50px;
    }

    @include mq($from: extra-wide) {
      font-size: 25px;
    }
  }

  .h2 {
    font-size: 50px;
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 60px;
    text-align: center;

    @include mq($from: desktop) {
      font-size: 80px;
    }

    @include mq($from: extra-wide) {
      font-size: 130px;
    }
  }
}

.scroll-next {
  display: block;
  margin-top: 30px;
  text-align: center;

  @include mq($from: desktop) {
    margin-top: 60px;
  }

  img {
    transform: rotate(90deg);
    width: 30px;

    @include mq($from: desktop) {
      width: 50px;
    }
  }
}
