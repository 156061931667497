.sr-only {
  @include visually-hidden();
}

.text-blue {
  color: $blue;
}

.text-secondary {
  color: $secondary !important;
}

.text-pink {
  color: $pink-700;
}

.bg-secondary {
  background-color: $secondary !important;
}

.grecaptcha-badge {
  display: none;
}

.steel-text {
  color: $steel;
}
