
@import '../../scss/config/config';
@import 'sass-mq/mq';

.application {
  align-items: center;
  border-bottom: $border-width solid $border-color;
  color: $gray-500;
  display: grid;
  gap: $spacer;
  grid-template-areas:
    'user actions'
    'job actions';
  grid-template-columns: 1fr auto;
  padding: 15px 0;

  @include mq($from: tablet) {
    grid-template-areas: 'user job favorite actions';
    grid-template-columns: 6fr 3fr 24px 2fr;
  }

  &.application-with-address {
    grid-template-areas:
      'user actions'
      'job actions'
      'address actions';
    grid-template-columns: 1fr auto auto;

    @include mq($from: tablet) {
      grid-template-areas: 'user job address favorite actions';
      grid-template-columns: 4fr 3fr 3fr 24px 2fr;
    }
  }
}

.application-user-section {
  align-items: center;
  display: flex;
  grid-area: user;
}

.application-job-section {
  grid-area: job;
}

.application-address {
  grid-area: address;
}

.application-favorite {
  background: none;
  border: 0;
  padding: 0;
}

.application-actions {
  grid-area: actions;
  margin-left: auto;
}

.candidate-avatar-wrapper {
  margin-right: 10px;
}

.candidate-avatar,
.empty-avatar {
  height: 40px;
  width: 40px;
}

.empty-avatar {
  background-color: $light;

  .empty-avatar-text {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
  }
}

.candidate-age-city,
.application-address {
  color: #8c8c8c;

  .candidate-age::after {
    content: '•';
    margin: 0 5px;
  }

  .candidate-age.before::before {
    content: '•';
    margin: 0 5px;
  }
}

.application-job {
  background-color: $light;
  border-radius: 10px;
  font-size: 12px;
  max-width: 120px;
  overflow: hidden;
  padding: 8px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-link {
  font-size: 14px;
  text-align: right;
}
