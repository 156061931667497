.contact {
  background-color: $gray-900;
  color: $white;

  @include mq($from: extra-wide) {
    padding-bottom: 260px;
  }

  .title {
    font-family: $special-font;
    font-size: 52px;
    font-weight: 900;
    line-height: 1.2em;
    margin-bottom: 60px;
    position: relative;
    z-index: 1;

    @include mq($from: mobile) {
      font-size: 66px;
    }

    @include mq($from: wide) {
      font-size: 100px;
      margin-bottom: 100px;
    }

    @include mq($from: extra-wide) {
      font-size: 130px;
    }
  }

  .subtitle {
    border-color: #ad94f6;
    border-style: solid;
    border-width: 1px 0;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2em;
    margin-bottom: 20px;
    padding-block: 18px;
    text-wrap: balance;

    @include mq($from: desktop) {
      border-bottom: 0;
      font-size: 26px;
    }

    @include mq($from: wide) {
      font-size: 40px;
      margin-bottom: 40px;
    }
  }

  .action {
    align-items: center;
    color: #ad94f6;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 900;
    gap: 16px;
    justify-content: center;

    @include mq($from: desktop) {
      border-bottom: 1px solid #ad94f6;
      flex-direction: row;
      font-size: 26px;
      justify-content: space-between;
      padding-bottom: 16px;
    }

    @include mq($from: wide) {
      font-size: 40px;
    }

    img {
      transform: rotate(90deg);
      width: 25px;

      @include mq($from: desktop) {
        transform: none;
        width: 39px;
      }
    }
  }
}

.contact-grid {
  display: grid;
  gap: 10px;
  padding-inline: 20px;

  @include mq($from: desktop) {
    gap: 32px;
    grid-template-columns: minmax(0, 1fr) 500px;
  }

  @include mq($from: wide-xl) {
    gap: 100px;
    grid-template-columns: minmax(0, 1fr) 660px;
  }
}

.contact-desc {
  text-align: center;

  @include mq($from: desktop) {
    text-align: left;
  }
}

.contact-desc-content {
  margin-inline: auto;
  max-width: 670px;
  width: 100%;

  @include mq($from: desktop) {
    margin-inline: 0;
  }
}

.contact-form {
  margin-inline: auto;
  max-width: 600px;
  width: 100%;

  @include mq($from: desktop) {
    max-width: unset;
    padding-top: 20px;
  }

  @include mq($from: extra-wide) {
    padding-top: 60px;
  }

  .form-group {
    margin-bottom: 0;

    + .form-group {
      margin-top: 26px;

      @include mq($from: desktop) {
        margin-top: 20px;
      }
    }
  }

  label {
    color: $white;
    margin-bottom: 9px;

    @include mq($from: desktop) {
      font-size: 20px;
    }
  }

  .form-control {
    border-radius: 8px;
    min-height: 50px;
    padding: 15px;

    @include mq($from: desktop) {
      font-size: 16px;
    }
  }

  .btn-primary {
    background-color: #ad94f6;
    border-radius: 50px;
    color: $gray-900;
    font-family: var(--body-font-family);
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    min-height: 56px;
    width: 100%;

    @include mq($from: desktop) {
      min-width: 214px;
      width: auto;
    }

    @include mq($from: extra-wide) {
      font-size: 24px;
      margin-top: 60px;
      min-height: 64px;
    }

    &:hover {
      background-color: $primary;
    }
  }

  .alert {
    margin-block: 20px 0;
  }
}
