.candidate-header {
  background-color: #ad94f6;
  padding-block: 30px;
  text-align: center;

  @include mq($from: tablet) {
    padding-block: 60px;
  }

  .logo {
    max-width: 100%;
    width: 162px;

    @include mq($from: tablet) {
      width: 250px;
    }
  }
}
