
@import '../../scss/config/config';

.applications-list.not-empty {
  min-height: 272px;
}

.date-badge {
  background-color: $blue;
  padding: 5px 10px;
  text-transform: capitalize;
}

