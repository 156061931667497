.candidates-content {
  background-color: $white;
  margin-left: calc(-1* var(--bs-gutter-x));
  padding-top: 20px;
  width: calc(100% + var(--bs-gutter-x) * 2);

  @include mq($from: desktop) {
    border-radius: 16px;
    margin-left: 0;
    width: 100%;
  }
}

.new-candidates-content {
  @include mq($from: desktop) {
    background-color: $purple-200;
    border: 1px solid $primary;
    border-radius: 16px;
    margin-inline: 20px;
    width: calc(100% - 40px);
  }

  .candidates-matching-title {
    color: $primary;
    font-weight: 500;
  }
}

.candidates-matching-title {
  color: $steel;
  font-size: 20px;
  margin-block: 20px 12px;
  padding-inline: 20px;
}

.candidates-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  padding: 24px 20px;

  @include mq($from: mobile) {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  }
}

.candidate-card {
  aspect-ratio: 204 / 242;
  border-radius: 8px;
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 242px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;

  &:hover {
    color: $white;

    .candidate-image {
      @include mq($from: desktop) {
        transform: scale(1.1);
      }
    }
  }

  &.is-rare {
    border: 4px solid $pink-700;
  }

  .candidate-image {
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: transform .3s;
    width: 100%;
  }

  .candidate-actions,
  .candidate-infos {
    position: relative;
    z-index: 1;
  }

  .candidate-infos {
    margin-top: auto;
  }
}

.candidate-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .btn {
    background-color: $white;
    height: 40px;
    padding: 0;
    width: 40px;
  }

  .favorite {
    color: $gray-100;

    &.is-favorite {
      color: $pink-700;
    }
  }

  .addtocart {
    color: $primary;
  }
}

.candidate-infos {
  font-size: 14px;
  padding: 10px;

  p {
    margin-bottom: 0;
  }

  .name {
    font-size: 20px;
    font-weight: 700;

    .match-percentage {
      font-weight: 400;
    }
  }

  .searched-job {
    font-size: 12px;
    text-transform: uppercase;
  }

  .tag {
    background-color: $pink-700;
    border-radius: 4px;
    line-height: 1;
    margin-bottom: 6px;
    padding: 4px 4px 2px;
    width: fit-content;
  }
}

.candidate-resume-detail {
  @include mq($from: desktop) {
    background-color: $light;
    border-radius: 16px;
    padding: 20px;
  }
}

.profile-block {
  background-color: $white;
  border: 1px solid $purple-200;
  border-radius: 16px;
  color: $body-color;
  margin-top: 20px;
  padding: 20px;

  @include mq($from: mobile) {
    margin-top: 32px;
  }
}

.profile-header {
  background-color: $white;
  border-radius: 16px;
  color: $body-color;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px;
  position: relative;

  @include mq($from: desktop) {
    background-color: transparent;
    padding: 0;
  }

  @include mq($from: wide) {
    flex-direction: row;
  }
}

.profile-info {
  display: grid;
  gap: 32px;

  @include mq($from: mobile) {
    grid-template-columns: 200px minmax(0, 1fr);
  }

  .content {
    @include mq($from: mobile) {
      padding-top: 20px;
    }
  }

  .profil-img {
    border-radius: 8px;
  }

  .candidate-name {
    font-size: 22px;
    font-weight: 500;
  }

  .candidate-metas {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;

    &:empty {
      display: none;
    }
  }

  .experience,
  .degrees {
    background-color: $light;
    border: 1px solid $steel;
    border-radius: 8px;
    color: $steel;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 8px;
    width: fit-content;
  }

  .degrees {
    margin-top: 12px;
  }
}

.profil-actions {
  align-self: center;
  background-color: $white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-inline: auto;
  max-width: 100%;
  padding: 20px;
  text-align: center;
  text-wrap: pretty;
  width: 400px;

  @include mq($from: wide) {
    margin-inline: auto 0;
    max-width: 400px;
    padding: 32px;
    width: auto;
  }

  @include mq($from: wide-xl) {
    width: 400px;
  }

  p {
    margin-bottom: 0;

    + .btn {
      margin-top: 16px;
    }
  }

  .btn {
    align-items: center;
    display: flex;
    font-weight: 500;
    gap: 10px;
    justify-content: center;
    line-height: 1em;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .svg-bulle {
    min-width: 25px;
  }

  .svg-call {
    min-width: 17px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  &.anonymous {
    background-color: $purple-200;
    gap: 8px;
  }
}

.list-icon {
  li {
    align-items: center;
    color: $steel;
    display: grid;
    font-size: 14px;
    font-weight: 500;
    gap: 16px;
    grid-template-columns: auto 16px;

    + li {
      border-top: 1px solid $purple-200;
      margin-top: 16px;
      padding-top: 16px;
    }
  }
}

.profile-secondary {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;

  @include mq($from: mobile) {
    flex-direction: row;
    gap: 32px;
    margin-top: 32px;
  }

  .item {
    background-color: $white;
    border: 1px solid $purple-200;
    border-radius: 16px;
    flex: 1;
    padding: 20px;

    &:nth-child(3) {
      @include mq($from: mobile, $until: wide) {
        flex: 100%;
      }
    }
  }

  .title {
    color: $primary;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 32px;
  }
}

.profile-tertiary {
  .title {
    align-items: center;
    display: flex;
    font-size: 16px;
    gap: 8px;
    line-height: 1;
    margin-bottom: 32px;
  }

  .experience {
    + .experience {
      margin-top: 20px;
    }
  }

  .experience-info {
    margin-bottom: 8px;
  }

  .experience-description {
    background-color: #f5f4fb;
    border: 1px solid $purple-200;
    border-radius: 8px;
    color: $steel;
    min-height: 4lh;
    padding: 10px 20px;
  }
}

.profile-skills {
  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .candidate-skill {
    + .candidate-skill {
      margin-top: 32px;
    }
  }

  .skill-category {
    color: $primary;
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.profile-documents {
  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .list-icon {
    li {
      display: block;
    }
  }

  .document-file {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: minmax(0, 1fr) 30px;
    text-decoration: none;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .svg-download {
      background-color: $primary;
      border-radius: 6px;
      height: 30px;
      padding: 6px;
      width: 30px;
    }
  }
}
