
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-filter {
  display: flex;
  justify-content: end;
  margin: 32px 0;
}

.resumes-header-filter {
  .dropdown-toggle {
    background: none;
    border: 0;
    color: $white;
    padding: 0 18px;

    &::after {
      content: none;
    }

    svg {
      margin-left: 18px;
      transition: transform .3s;
    }
  }

  .dropdown-item {
    display: inline-flex;
    font-size: 14px;
    gap: 5px;
  }

  .form-check-input {
    cursor: pointer;
    margin-top: 2px;
  }
}

.resume-filter {
  &:has(.dropdown-menu.show) {
    .dropdown-toggle svg {
      transform: rotate(180deg);
    }
  }
}
