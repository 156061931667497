.page-cdtn {
  --header-height: 84px;
  --body-font-family: Roboto, #{$font-family-sans-serif};

  font-family: var(--body-font-family);

  @include mq($from: tablet) {
    --header-height: 90px;
  }

  &.home {
    .content {
      padding-top: var(--header-height);
    }
  }

  .content {
    padding-top: calc(40px + var(--header-height));
  }

  .h1,
  .h2 {
    font-family: $special-font, $font-family-base;
  }
}

.container-max {
  margin-inline: auto;
  max-width: 1640px;
  padding-inline: var(--bs-gutter-x, .75rem);
  width: 100%;
}

.homecdtn-section {
  padding-block: 30px 40px;
  scroll-margin-top: var(--header-height);

  @include mq($from: desktop) {
    border-radius: 50px 50px 0 0;
    margin-top: -50px;
    padding-block: 50px 130px;
  }
}

.content-grid {
  display: grid;
  gap: 40px;
  grid-template-columns: minmax(0, 1fr);

  @include mq($from: desktop) {
    gap: 80px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($from: extra-wide) {
    gap: 127px;
  }

  .content-media,
  .content-desc {
    background-color: $white;
    border: 1px solid $gray-900;
    border-radius: 25px;
    box-shadow: 10px 10px 6px 0 #1d1d1f99;
    margin-inline: auto;
    max-width: 520px;
    padding: 13px;
    text-align: center;
    width: 100%;

    @include mq($from: desktop) {
      border-radius: 50px;
      box-shadow: 20px 20px 6px 0 #1d1d1f99;
      font-size: 20px;
      padding: 15px;
    }
  }

  .content-media {
    padding: 24px 16px;
  }

  .content-media-alt {
    align-self: end;
    position: relative;
    text-align: center;
    z-index: 2;

    .shadow-effect {
      bottom: -10px;
      left: -15px;
      position: absolute;
      width: calc(100% + 30px);

      @include mq($from: desktop) {
        left: -30px;
        width: calc(100% + 60px);
      }
    }
  }

  .title {
    font-size: 50px;
    font-weight: 900;
    margin-block: 30px 23px;

    @include mq($from: wide-xl) {
      font-size: 90px;
      margin-block: 20px 30px;
    }
  }

  .economic & {
    .title {
      @include mq($from: wide-xl) {
        font-size: 75px;
      }

      @include mq($from: extra-wide) {
        font-size: 85px;
      }
    }
  }
}

.visibility,
.economic {
  background-color: #ffcfe8;
}

.proactive {
  background-color: $primary;
}
