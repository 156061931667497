.landing-candidate {
  --body-font-family: Roboto, #{$font-family-sans-serif};

  font-family: var(--body-font-family);

  > .content {
    background-color: #ad94f6;
    padding-inline: 20px;

    @include mq($from: tablet) {
      padding-inline: 40px;
    }

    @include mq($from: wide) {
      padding-inline: 90px;
    }
  }

  .h1,
  .h2 {
    font-family: $special-font, $font-family-base;
  }
}

.candidate-section {
  --radius: 20px;

  background-color: $gray-900;
  border-radius: var(--radius) var(--radius) 0 0;
  color: $white;
  min-height: 60vh;
  padding-block: 60px calc(60px + var(--radius));
  padding-inline: 20px;
  place-content: center;
  position: relative;
  text-align: center;

  @include mq($from: tablet) {
    padding-inline: 40px;
  }

  @include mq($from: desktop) {
    --radius: 40px;

    padding-block: 100px calc(100px + var(--radius));
  }

  + .candidate-section {
    margin-top: calc(-1 * var(--radius));
  }

  &.light {
    background-color: $cdtn-gray-bg;
  }

  &.purple {
    background-color: #7557ed;
  }

  &.candidate-why {
    padding-bottom: clamp(30px, 8vw, 140px);
  }
}

.candidate-what,
.candidate-how {
  padding-bottom: 130px;
}

.candidate-container {
  margin-inline: auto;
  max-width: 100%;
  width: 1090px;
}

.candidate-surround {
  position: relative;
  white-space: nowrap;
  z-index: -1;

  img {
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(0, -10%, 0);
    width: 100%;
    z-index: -1;
  }
}

.candidate-grid {
  background-color: $pink-700;
  border-radius: 20px;
  padding-inline: 20px;

  @include mq($from: wide) {
    display: flex;
    flex-direction: row-reverse;
  }

  &.light {
    background-color: #f8f7ec;
    color: $body-color;
  }

  .candidate-why & {
    display: block;
    padding-bottom: 30px;

    @include mq($from: desktop) {
      padding-bottom: 50px;
    }
  }

  .content-icon {
    margin-top: -34px;
    width: 184px;

    @include mq($from: desktop) {
      width: 320px;
    }
  }

  .h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.1em;
    margin-block: 30px 50px;
    text-wrap: balance;

    @include mq($from: tablet) {
      font-size: 80px;
    }

    @include mq($from: wide) {
      margin-bottom: 80px;
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 500;
    text-wrap: balance;

    @include mq($from: tablet) {
      font-size: 20px;
    }
  }

  .media {
    --decal: -50px;
    bottom: var(--decal);
    margin-top: var(--decal);
    position: relative;

    @include mq($from: wide) {
      --decal: -30px;
      bottom: 0;
      margin-top: 0;
    }

    .img {
      max-width: 100%;

      @include mq($from: wide) {
        height: calc(100% + var(--decal) * -2);
        left: -30px;
        max-width: unset;
        position: relative;
        top: var(--decal);
      }
    }
  }
}

.prefooter {
  background-color: $gray-900;
  color: $white;
  padding-bottom: 18px;
  padding-inline: 20px;

  @include mq($from: tablet) {
    padding-inline: 40px;
  }

  .h2 {
    border-color: #f8f7ec;
    border-style: solid;
    border-width: 1px 0;
    font-size: 40px;
    font-weight: 900;
    margin: 0;
    padding-block: 42px;
    text-align: center;

    @include mq($from: desktop) {
      font-size: 80px;
      font-weight: 700;
    }

    @include mq($from: wide) {
      font-size: 90px;
      padding-block: 70px;
    }
  }
}
