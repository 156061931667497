[class^='body view-register-step-'],
[class^='body view-register-affiliate-step-'] {
  background-color: $cdtn-gray-bg;
}

.page-register {
  --register-container: 720px;

  margin-inline: auto;
  max-width: 100%;
  min-height: 100vh;
  padding: 20px 20px 0;
  width: var(--register-container);

  @include mq($from: tablet) {
    padding: 64px 64px 0;
  }

  .register-headhunter,
  .register-register-affiliate {
    padding-bottom: 120px;

    @include mq($from: desktop) {
      padding-bottom: 220px;
    }
  }

  .logo {
    justify-self: start;
    margin-top: 0;
    min-width: 282px;
    width: 282px;
  }

  .h3 {
    font-size: 32px;
    margin-bottom: 32px;

    @include mq($from: tablet) {
      margin-bottom: 62px;
    }
  }

  .suptitle {
    margin-bottom: 8px;

    @include mq($from: tablet) {
      font-size: 20px;
    }
  }

  .own-account-reminder {
    font-size: 20px;
    margin-top: 32px;

    @include mq($from: tablet) {
      margin-top: 62px;
    }

    a {
      font-weight: 700;
      text-decoration: none;
    }
  }

  .register-submit {
    background:
      linear-gradient(
        0deg,
        rgba(250, 250, 250, 1) 0%,
        rgba(250, 250, 250, 1) 75%,
        rgba(250, 250, 250, 0) 100%
      );
    bottom: 0;
    left: 50%;
    max-width: 100%;
    padding-block: 30px;
    padding-inline: 20px;
    position: fixed;
    transform: translateX(-50%);
    width: var(--register-container);

    @include mq($from: tablet) {
      padding-inline: 64px;
    }

    @include mq($from: desktop) {
      padding-block: 60px 100px;
    }

    .btn {
      width: 100%;
    }
  }
}

.header-subtitle {
  color: $pink-700;
  font-family: $headings-font-family;
  font-size: 20px;
  font-weight: 700;

  @include mq($from: tablet) {
    font-size: 24px;
  }

  .page-register-affiliate & {
    color: currentColor;
    font-size: 16px;
    font-weight: 400;

    @include mq($from: tablet) {
      font-size: 24px;
    }
  }
}

.progress-bar {
  border-radius: 8px;
}

.view-register-step-1 {
  .progress-bar {
    width: 25%;
  }
}

.view-register-step-2 {
  .progress-bar {
    width: 50%;
  }
}

.view-register-step-3 {
  .progress-bar {
    width: 75%;
  }
}

.view-register-step-4 {
  .progress-bar {
    width: 100%;
  }
}

.view-register-affiliate-step-1 {
  .progress-bar {
    width: 33.33%;
  }
}

.view-register-affiliate-step-2 {
  .progress-bar {
    width: 66.66%;
  }
}

.view-register-affiliate-step-3 {
  .progress-bar {
    width: 100%;
  }
}

.step3-forms {
  .form-header {
    margin-block: 32px;

    @include mq($from: tablet) {
      margin-block: 62px;
    }

    .title {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .subtitle {
      margin-bottom: 0;
    }
  }

  .form-check-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-check-label {
    border-color: $steel;
    color: $gray-900;
    font-size: 20px;
    height: 74px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
}

.view-register-affiliate-payment {
  background-color: $cdtn-gray-bg;

  @include mq($from: desktop) {
    background: linear-gradient(90deg, $cdtn-gray-bg 0%, $cdtn-gray-bg 65%, $pink-200 65%, $pink-200 100%);
  }
}
