
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-filter {
  align-items: center;
  display: flex;
  gap: $grid-gutter-width * .5;
  margin: 15px 0;
}

.resumes-filter-label {
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}

.resumes-header-filter {
  .dropdown-toggle {
    background: none;
    border: 0;
    color: $white;
    font-weight: 700;
    padding: 0;
    text-transform: uppercase;
  }

  .dropdown-item {
    display: inline-flex;
    font-size: 14px;
    gap: 5px;
  }

  .form-check-input {
    cursor: pointer;
    margin-top: 2px;
  }
}
