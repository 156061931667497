
@import '../../scss/config/config';

.form-upper {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}

