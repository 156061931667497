.candidate-hero {
  .candidate-container {
    container-type: inline-size;
  }

  .h1 {
    font-size: 13cqw;
    font-weight: 900;
    line-height: 1em;
    position: relative;
    z-index: 1;
  }

  .scroll-next {
    display: block;
    margin-top: 80px;

    @include mq($from: mobile) {
      display: none;
    }

    img {
      transform: rotate(90deg);
      width: 36px;
    }
  }
}
